import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Main from './main/main'
import PhoneVerification from './pages/PhoneVerification'
import Failed from './pages/Failed'
import Success from './pages/Success'
import TermsOfService from './pages/TermsOfService'
import PrivacyPolicy from './pages/PrivacyPolicy'
import './App.css'

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="/phone-verification" element={<PhoneVerification />} />
				<Route path="/failed" element={<Failed />} />
				<Route path="/success" element={<Success />} />
				<Route path="/regulamin" element={<TermsOfService />} />
				<Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
			</Routes>
		</Router>
	)
}

export default App
