import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './main.css'
import axios from 'axios'
import { API_ENDPOINTS } from '../config'

function AdminInfoPopup({ onClose }) {
	return (
		<div className="admin-popup-overlay">
			<div className="admin-popup-content">
				<button onClick={onClose} className="admin-popup-close">
					<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6 18L18 6M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>

				<div className="admin-popup-header">
					<h2>Informacja dla administratora HotPay</h2>
					<p>Opis procesu płatności w serwisie Zasilki.com</p>
				</div>

				<div className="admin-popup-body">
					<div className="process-step">
						<h3>1. Analiza sytuacji użytkownika</h3>
						<p>
							Użytkownik opisuje swoją sytuację życiową w formularzu na stronie głównej. System analizuje tekst i
							przedstawia listę dostępnych zasiłków.
						</p>
					</div>

					<div className="process-step">
						<h3>2. Prezentacja zasiłków</h3>
						<p>
							Pierwszy zasiłek jest pokazywany za darmo jako przykład. Pozostałe są ukryte za paywallem. Każdy kolejny
							zasiłek wymaga osobnej płatności 74.99 zł.
						</p>
					</div>

					<div className="process-step">
						<h3>3. Proces płatności</h3>
						<ul>
							<li>Użytkownik klika "Odblokuj" przy wybranym zasiłku</li>
							<li>Podaje numer telefonu do otrzymania kodu dostępu</li>
							<li>Jest przekierowany do bramki HotPay (74.99 zł)</li>
							<li>Po płatności otrzymuje SMS z kodem dostępu</li>
							<li>Wpisuje kod i uzyskuje dostęp do informacji o zasiłku</li>
						</ul>
					</div>

					<div className="process-note">
						<strong>Ważne:</strong> Każda płatność (74.99 zł) odblokowuje dostęp do informacji o jednym, konkretnym
						zasiłku. Jeśli użytkownik chce uzyskać informacje o kolejnym zasiłku, musi dokonać kolejnej płatności.
					</div>
				</div>
			</div>
		</div>
	)
}

function LoadingState() {
	const [currentStep, setCurrentStep] = useState(0);
	const steps = [
		'Analizujemy Twoją sytuację...',
		'Sprawdzamy dostępne świadczenia...',
		'Przygotowujemy szczegółowe informacje...',
		'Finalizujemy wyniki...'
	];
	const tips = [
		{
			icon: (
				<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 8v4l2 2m6-2a8 8 0 11-16 0 8 8 0 0116 0z"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			text: 'Analiza zazwyczaj trwa 15-30 sekund'
		},
		{
			icon: (
				<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			text: 'Sprawdzamy ponad 100 różnych świadczeń i zasiłków'
		},
		{
			icon: (
				<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M13 10V3L4 14h7v7l9-11h-7z"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			text: 'Nasze algorytmy są aktualizowane codziennie'
		}
	];

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentStep((prev) => (prev + 1) % steps.length);
		}, 2000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="main__loading-state">
			<div className="main__loading-progress">
				<div className="main__loading-message">{steps[currentStep]}</div>
				<div className="main__loading-bar" />
				<div className="main__loading-substep">
					Sprawdzamy Twoje uprawnienia do świadczeń...
				</div>
			</div>

			<div className="main__loading-tips">
				<div className="main__loading-tips-title">
					<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					Podczas analizy warto wiedzieć:
				</div>
				<div className="main__loading-tips-list">
					{tips.map((tip, index) => (
						<div key={index} className="main__loading-tip">
							{tip.icon}
							<span>{tip.text}</span>
						</div>
					))}
				</div>
			</div>

			<div className="main__loading-placeholder">
				<div className="main__loading-placeholder-item loading-skeleton" />
				<div className="main__loading-placeholder-item loading-skeleton" />
				<div className="main__loading-placeholder-item loading-skeleton" />
			</div>
		</div>
	);
}

function Main() {
	const [isLoading, setIsLoading] = useState(false)
	const [unlockCode, setUnlockCode] = useState('')
	const [isUnlocked, setIsUnlocked] = useState(false)
	const [description, setDescription] = useState(() => {
		return localStorage.getItem('description') || ''
	})
	const [benefits, setBenefits] = useState(() => {
		const savedBenefits = localStorage.getItem('benefits')
		return savedBenefits ? JSON.parse(savedBenefits) : []
	})
	const [error, setError] = useState(null)
	const navigate = useNavigate()
	const [showAdminPopup, setShowAdminPopup] = useState(false)

	useEffect(() => {
		// Show popup after a short delay
		const timer = setTimeout(() => {
			setShowAdminPopup(true)
		}, 1000)

		return () => clearTimeout(timer)
	}, [])

	const handleSubmit = async () => {
		if (!description.trim()) return

		setIsLoading(true)
		setError(null)

		try {
			// Save description immediately
			localStorage.setItem('description', description)

			const response = await fetch(API_ENDPOINTS.analyze, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ description }),
			})

			if (!response.ok) {
				throw new Error('Błąd serwera')
			}

			const data = await response.json()
			if (data.error) {
				throw new Error(data.error)
			}

			// Save benefits to localStorage
			localStorage.setItem('benefits', JSON.stringify(data.benefits))
			setBenefits(data.benefits)

			// Clear the description state and localStorage
			setDescription('')
			localStorage.removeItem('description')

			// Scroll to results
			setTimeout(() => {
				const resultsContainer = document.querySelector('.main__results-container')
				if (resultsContainer) {
					const yOffset = -20; // Add some padding at the top
					const y = resultsContainer.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({ top: y, behavior: 'smooth' });
				}
			}, 100)
		} catch (err) {
			setError(err.message)
			console.error('Error:', err)
		} finally {
			setIsLoading(false)
		}
	}

	const handleUnlock = async () => {
		try {
			const response = await axios.post(API_ENDPOINTS.verifyCode, {
				code: unlockCode,
			})

			if (response.data.valid) {
				setIsUnlocked(true)
				setUnlockCode('') // Clear the input
			} else {
				// Show error message
				setError('Nieprawidłowy kod dostępu')
				setTimeout(() => setError(null), 3000)
			}
		} catch (error) {
			console.error('Error verifying code:', error)
			setError('Wystąpił błąd podczas weryfikacji kodu')
			setTimeout(() => setError(null), 3000)
		}
	}

	const handleBuyCode = () => {
		navigate('/phone-verification')
	}

	const regenerateResponses = async () => {
		// Clear current benefits and re-fetch
		setBenefits([])
		await handleSubmit()
	}

	const clearStoredData = () => {
		localStorage.removeItem('benefits')
		localStorage.removeItem('description')
		setBenefits([])
		setDescription('')
	}

	return (
		<>
			{showAdminPopup && <AdminInfoPopup onClose={() => setShowAdminPopup(false)} />}
			<div className="main__section">
				<div className="main__input-container">
					<h1>Odkryj swoje prawa do zasiłków</h1>
					<p className="main__input-subtitle">
						Opisz swoją sytuację, a my pomożemy Ci znaleźć wszystkie zasiłki i świadczenia, które Ci przysługują. To
						proste i zajmie tylko chwilę.
					</p>

					{isLoading ? (
						<LoadingState />
					) : (
						<>
							<div className="main__input-wrapper">
								<label className="main__input-label">
									<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<circle
											cx="9"
											cy="7"
											r="4"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M23 21v-2a4 4 0 0 0-3-3.87"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16 3.13a4 4 0 0 1 0 7.75"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									Opisz swoją sytuację życiową:
								</label>

								<div className="main__input-field">
									<textarea
										value={description}
										onChange={e => setDescription(e.target.value)}
										placeholder="Np.: Jestem samotną matką dwójki dzieci w wieku 5 i 8 lat. Pracuję na pół etatu i zarabiam 2800 zł miesięcznie..."
									/>
									<button className="main__input-send-icon" onClick={handleSubmit} disabled={isLoading}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round">
											<line x1="22" y1="2" x2="11" y2="13"></line>
											<polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
										</svg>
									</button>
								</div>

								<button
									className={`main__submit-button ${isLoading ? 'loading' : ''}`}
									onClick={handleSubmit}
									disabled={isLoading || benefits.length > 0}>
									<span className="button-text">
										{isLoading ? 'Analizujemy Twoją sytuację...' : 'Sprawdź dostępne zasiłki'}
									</span>
									<svg
										className="button-arrow"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M4.16666 10H15.8333M15.8333 10L10 4.16669M15.8333 10L10 15.8334"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<div className="button-loader"></div>
								</button>
							</div>

							{benefits.length > 0 && (
								<>
									<div className="main__divider">
										<span className="main__divider-text">Dostępne świadczenia</span>
									</div>
									<div className="main__results-container">
										{/* First benefit always visible */}
										{benefits.length > 0 && (
											<div className="main__results-item">
												<div className="main__results-item-header">
													<h3>{benefits[0].name}</h3>
													<span>{benefits[0].amount}</span>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="eligibility">Dlaczego przysługuje Ci ten zasiłek?</h3>
													<p>{benefits[0].eligibility}</p>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="steps">Jak uzyskać ten zasiłek w prostych krokach?</h3>
													<p>{benefits[0].howToGet}</p>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="tips">Wskazówki, które przyspieszą Twoje wnioski:</h3>
													<ul>
														{benefits[0].tips.map((tip, index) => (
															<li key={index}>{tip}</li>
														))}
													</ul>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="faq">Najczęściej zadawane pytania przez urzędników:</h3>
													<ul>
														{benefits[0].faq?.map((item, index) => (
															<li key={index}>
																<strong>P: {item.question}</strong>
																<p>O: {item.answer}</p>
															</li>
														))}
													</ul>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="issues">Co zrobić w przypadku problemów:</h3>
													<ul>
														{benefits[0].commonIssues?.map((item, index) => (
															<li key={index}>
																<strong>{item.issue}</strong>
																<p>{item.solution}</p>
															</li>
														))}
													</ul>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="interview">Jak rozmawiać z urzędnikiem:</h3>
													<ul>
														{benefits[0].interviewTips?.map((tip, index) => (
															<li key={index}>{tip}</li>
														))}
													</ul>
												</div>
											</div>
										)}
										{/* Rest of benefits behind paywall */}
										{benefits.slice(1).map((benefit, index) => (
											<div key={index} className={`main__results-item ${isUnlocked ? '' : 'blurred'}`}>
												<div className="main__results-item-header">
													<h3>{benefit.name}</h3>
													<span>{benefit.amount}</span>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="eligibility">Dlaczego przysługuje Ci ten zasiłek?</h3>
													<p>{benefit.eligibility}</p>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="steps">Jak uzyskać ten zasiłek w prostych krokach?</h3>
													<p>{benefit.howToGet}</p>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="tips">Wskazówki, które przyspieszą Twoje wnioski:</h3>
													<ul>
														{benefit.tips.map((tip, index) => (
															<li key={index}>{tip}</li>
														))}
													</ul>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="faq">Najczęściej zadawane pytania przez urzędników:</h3>
													<ul>
														{benefit.faq?.map((item, index) => (
															<li key={index}>
																<strong>P: {item.question}</strong>
																<p>O: {item.answer}</p>
															</li>
														))}
													</ul>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="issues">Co zrobić w przypadku problemów:</h3>
													<ul>
														{benefit.commonIssues?.map((item, index) => (
															<li key={index}>
																<strong>{item.issue}</strong>
																<p>{item.solution}</p>
															</li>
														))}
													</ul>
												</div>
												<div className="main__results-item-explanation">
													<h3 data-icon="interview">Jak rozmawiać z urzędnikiem:</h3>
													<ul>
														{benefit.interviewTips?.map((tip, index) => (
															<li key={index}>{tip}</li>
														))}
													</ul>
												</div>
												{!isUnlocked && (
													<div className="main__paywall-overlay">
														<div className="main__paywall-content-card">
															<div className="main__paywall-header">
																<h3 className="main__paywall-title">Odblokuj informacje o tym zasiłku</h3>
																<p className="main__paywall-subtitle">
																	Otrzymasz szczegółową instrukcję jak złożyć wniosek i maksymalnie przyspieszyć wypłatę
																</p>

																<div className="main__paywall-pricing">
																	<div className="main__paywall-original-price">
																		<span className="strike-through">149.99 PLN</span>
																		<div className="discount-badge">-50%</div>
																	</div>
																	<div className="main__paywall-price">
																		74.99 <small>PLN</small>
																	</div>
																	<div className="main__paywall-price-note">
																		Jednorazowa opłata za dostęp do tego zasiłku
																	</div>
																</div>
															</div>

															<div className="main__paywall-benefits">
																<div className="main__paywall-benefit">
																	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																	<span>Pełne informacje o kwocie i warunkach</span>
																</div>
																<div className="main__paywall-benefit">
																	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																	<span>Instrukcja krok po kroku jak złożyć wniosek</span>
																</div>
																<div className="main__paywall-benefit">
																	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M13 10V3L4 14h7v7l9-11h-7z"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																	<span>Wskazówki jak przyspieszyć wypłatę</span>
																</div>
																<div className="main__paywall-benefit">
																	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																	<span>Bezpieczna płatność online</span>
																</div>
															</div>

															<button className="main__buy-code-button" onClick={handleBuyCode}>
																Odblokuj ten zasiłek
															</button>

															<div className="main__code-section">
																<div className="main__or-divider">lub</div>
																<div className="main__code-input-container">
																	<input
																		type="text"
																		className="main__code-input"
																		placeholder="Wprowadź kod dostępu"
																		value={unlockCode}
																		onChange={e => setUnlockCode(e.target.value)}
																	/>
																	<button className="main__unlock-button" onClick={handleUnlock}>
																		Odblokuj
																	</button>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										))}
									</div>
								</>
							)}
						</>
					)}
					{error && <div className="main__error-message">Wystąpił błąd: {error}</div>}
				</div>
			</div>
			<footer className="main__footer">
				<div className="main__footer-content">
					<div className="main__footer-links">
						<a href="mailto:blonekdev@gmail.com">Kontakt</a>
						<a href="/polityka-prywatnosci">Polityka Prywatności</a>
						<a href="/regulamin">Regulamin</a>
					</div>
					<div className="main__footer-copyright">
						<p>© 2024 BLONEKDEV</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Main
