import React from 'react'
import { Link } from 'react-router-dom'
import './Status.css'

const Failed = () => {
	return (
		<div className="status-container">
			<div className="status-card">
				<div className="status-icon failed">
					<i className="fas fa-times-circle"></i>
				</div>
				<h1 className="status-title">Płatność nie powiodła się</h1>
				<p className="status-message">Przepraszamy, ale wystąpił problem z płatnością. Spróbuj ponownie później.</p>
				<Link to="/" className="status-link">
					Wróć do strony głównej
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path
							d="M4.16666 10H15.8333M15.8333 10L10 4.16669M15.8333 10L10 15.8334"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Link>
			</div>
		</div>
	)
}

export default Failed
