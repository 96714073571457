import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_ENDPOINTS } from '../config'
import './PhoneVerification.css'
import {
	IoArrowBackOutline,
	IoShieldCheckmarkOutline,
	IoLockClosedOutline,
	IoTimeOutline,
	IoCardOutline,
} from 'react-icons/io5'

function PhoneVerification() {
	const [phone, setPhone] = useState('')
	const [phoneError, setPhoneError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate()

	const validatePhone = phone => {
		const re = /^\+48[0-9]{9}$/
		return re.test(phone)
	}

	const handlePhoneChange = e => {
		const value = e.target.value
		setPhone(value)
		if (value && !validatePhone(value)) {
			setPhoneError('Wprowadź poprawny numer telefonu w formacie +48XXXXXXXXX')
		} else {
			setPhoneError('')
		}
	}

	const handleBack = () => {
		navigate('/')
	}

	const handleSubmit = async event => {
		event.preventDefault()

		if (!validatePhone(phone)) {
			setPhoneError('Wprowadź poprawny numer telefonu w formacie +48XXXXXXXXX')
			return
		}

		try {
			setIsLoading(true)
			const response = await axios.post(API_ENDPOINTS.initiatePayment, {
				phone,
				amount: 74.99,
			})

			if (response.data?.url) {
				const orderId = new URLSearchParams(response.data.url).get('ID_ZAMOWIENIA')
				if (orderId) {
					localStorage.setItem('pending_payment_id', orderId)
				}
				window.location.href = response.data.url
			} else {
				throw new Error('Invalid response from payment server')
			}
		} catch (error) {
			console.error('Payment error:', error)
			setPhoneError(
				error.response?.data?.error || 'Wystąpił błąd podczas inicjowania płatności. Spróbuj ponownie później.'
			)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="phone-verification-container">
			<button onClick={handleBack} className="back-button">
				<IoArrowBackOutline /> Powrót
			</button>
			<div className="form-wrapper">
				<div className="header-section">
					<h1 className="form-title">Dostęp do Zasilki.com</h1>
					<p className="form-subtitle">
						Wprowadź numer telefonu, aby otrzymać kod dostępu, dzięki któremu uzyskasz dostęp do wszystkich funkcji
					</p>
				</div>

				<form onSubmit={handleSubmit} className="form">
					<div className="form-group">
						<label htmlFor="phone" className="form-label">
							Numer telefonu
						</label>
						<div className="input-wrapper">
							<input
								id="phone"
								name="phone"
								type="tel"
								placeholder="+48XXXXXXXXX"
								value={phone}
								onChange={handlePhoneChange}
								required
								className="form-input"
							/>
						</div>
						{phoneError && <p className="error-text">{phoneError}</p>}
					</div>

					<div className="trust-indicators">
						<div className="trust-indicator">
							<IoTimeOutline className="trust-icon" />
							<span>Natychmiastowy dostęp po płatności</span>
						</div>
						<div className="trust-indicator">
							<IoCardOutline className="trust-icon" />
							<span>Jednorazowa opłata 74.99 PLN</span>
						</div>
						<div className="trust-indicator">
							<IoShieldCheckmarkOutline className="trust-icon" />
							<span>Bezpieczna płatność online</span>
						</div>
						<div className="trust-indicator">
							<IoLockClosedOutline className="trust-icon" />
							<span>Dane chronione zgodnie z RODO</span>
						</div>
					</div>

					<button type="submit" className="form-button" disabled={isLoading}>
						{isLoading ? 'Przetwarzanie...' : 'Przejdź do płatności'}
					</button>

					<div className="security-note">
						<IoShieldCheckmarkOutline className="security-icon" />
						<p>
							Twój numer telefonu jest bezpieczny z nami. Używamy go tylko do wysłania kodu dostępu i nigdy nie
							udostępniamy go osobom trzecim.
						</p>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PhoneVerification
