import React from 'react'
import { Link } from 'react-router-dom'
import './Legal.css'

function PrivacyPolicy() {
	return (
		<div className="legal-page">
			<div className="legal-container">
				<Link to="/" className="legal-back-button">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path
							d="M19 12H5M5 12L12 19M5 12L12 5"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					Wróć do strony głównej
				</Link>

				<h1>Regulamin i Polityka Prywatności</h1>

				<div className="legal-content">
					<section>
						<h2>Regulamin</h2>

						<section>
							<h3>1. Postanowienia ogólne</h3>
							<p>
								1.1. Niniejszy Regulamin określa zasady korzystania z serwisu internetowego Zasiłki.info, dostępnego pod
								adresem <a href="https://zasilki.info">https://zasilki.info</a>, prowadzonego przez BLONEKDEV Antoni
								Bartłomiej Blonkowski z siedzibą w Mławka 25, 13-240 Mławka, NIP: 5711727874, e-mail:{' '}
								<a href="mailto:blonekdev@gmail.com">blonekdev@gmail.com</a>.
							</p>
							<p>
								1.2. Regulamin jest udostępniony Użytkownikom nieodpłatnie za pośrednictwem strony internetowej Serwisu
								w formie, która umożliwia jego pobranie, utrwalenie i wydrukowanie.
							</p>
							<p>1.3. Warunkiem korzystania z Serwisu jest akceptacja niniejszego Regulaminu.</p>
						</section>

						<section>
							<h3>2. Definicje</h3>
							<p>
								2.1. <strong>Serwis</strong> – serwis internetowy Zasiłki.info dostępny pod adresem{' '}
								<a href="https://zasilki.info">https://zasilki.info</a>.
							</p>
							<p>
								2.2. <strong>Użytkownik</strong> – każda osoba korzystająca z Serwisu.
							</p>
							<p>
								2.3. <strong>Usługi</strong> – usługi świadczone drogą elektroniczną przez Administratora za
								pośrednictwem Serwisu.
							</p>
							<p>
								2.4. <strong>Administrator</strong> – BLONEKDEV Antoni Bartłomiej Blonkowski z siedzibą w Mławka 25,
								13-240 Mławka, NIP: 5711727874.
							</p>
						</section>

						<section>
							<h3>3. Rodzaj i zakres świadczonych usług</h3>
							<p>
								3.1. Serwis umożliwia Użytkownikom generowanie listy dostępnych zasiłków na podstawie opisu sytuacji
								życiowej Użytkownika, z wykorzystaniem OpenAI API.
							</p>
							<p>
								3.2. Jeden zasiłek jest wyświetlany Użytkownikowi bezpłatnie, pozostałe są dostępne po dokonaniu
								płatności.
							</p>
						</section>

						<section>
							<h3>4. Warunki świadczenia usług</h3>
							<p>
								4.1. Do korzystania z Serwisu niezbędne jest posiadanie urządzenia z dostępem do Internetu oraz
								przeglądarki internetowej obsługującej JavaScript i pliki cookies.
							</p>
							<p>
								4.2. Użytkownik zobowiązuje się do korzystania z Serwisu zgodnie z obowiązującym prawem i
								postanowieniami niniejszego Regulaminu.
							</p>
						</section>

						<section>
							<h3>5. Warunki zawierania i rozwiązywania umów</h3>
							<p>
								5.1. Umowa o świadczenie usług drogą elektroniczną zostaje zawarta w momencie rozpoczęcia korzystania z
								Serwisu przez Użytkownika.
							</p>
							<p>5.2. Użytkownik może w każdej chwili zakończyć korzystanie z usług Serwisu.</p>
						</section>

						<section>
							<h3>6. Dostępne metody płatności</h3>
							<p>
								6.1. Płatności za dostęp do dodatkowych informacji o zasiłkach realizowane są za pośrednictwem systemu
								płatności HotPay.
							</p>
							<p>6.2. Dostępne metody płatności obejmują płatności elektroniczne (e-przelew).</p>
							<p>6.3. Rozliczenia transakcji e-przelewem przeprowadzane są za pośrednictwem HotPay.</p>
						</section>

						<section>
							<h3>7. Realizacja usług</h3>
							<p>
								7.1. Dostęp do dodatkowych informacji o zasiłkach zostaje udostępniony Użytkownikowi po dokonaniu
								płatności i pozytywnej weryfikacji numeru telefonu poprzez kod dostępu wysłany za pomocą usługi
								Textbelt.
							</p>
						</section>

						<section>
							<h3>8. Reklamacje</h3>
							<p>
								8.1. Użytkownik ma prawo zgłaszać reklamacje dotyczące funkcjonowania Serwisu oraz świadczonych usług.
							</p>
							<p>
								8.2. Reklamacje należy składać na adres e-mail:{' '}
								<a href="mailto:blonekdev@gmail.com">blonekdev@gmail.com</a>.
							</p>
							<p>8.3. Administrator rozpatruje reklamację w terminie 14 dni od dnia jej otrzymania.</p>
						</section>

						<section>
							<h3>9. Prawo odstąpienia od umowy</h3>
							<p>
								9.1. Zgodnie z art. 38 pkt 13 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, Użytkownikowi nie
								przysługuje prawo odstąpienia od umowy o świadczenie usług, jeżeli Administrator wykonał w pełni usługę
								za wyraźną zgodą Użytkownika.
							</p>
						</section>

						<section>
							<h3>10. Ograniczenia w dostarczaniu usług</h3>
							<p>
								10.1. Serwis może nie być dostępny w niektórych krajach lub regionach ze względu na ograniczenia prawne
								lub techniczne.
							</p>
						</section>

						<section>
							<h3>11. Odpowiedzialność</h3>
							<p>
								11.1. Administrator dokłada wszelkich starań, aby informacje przedstawione w Serwisie były aktualne i
								rzetelne, jednak nie gwarantuje ich pełnej dokładności i kompletności.
							</p>
							<p>
								11.2. Informacje o dostępnych zasiłkach wygenerowane przez Serwis mają charakter informacyjny i nie
								stanowią wiążącej oferty ani porady prawnej.
							</p>
						</section>

						<section>
							<h3>12. Polityka prywatności</h3>
							<p>
								12.1. Zasady przetwarzania danych osobowych Użytkowników określa Polityka Prywatności stanowiąca
								integralną część niniejszego Regulaminu.
							</p>
						</section>

						<section>
							<h3>13. Postanowienia końcowe</h3>
							<p>13.1. Administrator zastrzega sobie prawo do zmiany Regulaminu.</p>
							<p>
								13.2. O wszelkich zmianach Regulaminu Użytkownicy zostaną poinformowani poprzez publikację na stronie
								Serwisu.
							</p>
							<p>13.3. W sprawach nieuregulowanych w Regulaminie mają zastosowanie przepisy prawa polskiego.</p>
						</section>
					</section>

					<section>
						<h2>Polityka Prywatności</h2>

						<section>
							<h3>1. Postanowienia Ogólne</h3>
							<p>
								1.1. Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych Użytkowników
								Serwisu Zasiłki.info.
							</p>
						</section>

						<section>
							<h3>2. Administrator Danych Osobowych</h3>
							<p>
								2.1. Administratorem danych osobowych jest BLONEKDEV Antoni Bartłomiej Blonkowski z siedzibą w Mławka
								25, 13-240 Mławka, NIP: 5711727874, e-mail: <a href="mailto:blonekdev@gmail.com">blonekdev@gmail.com</a>
								.
							</p>
						</section>

						<section>
							<h3>3. Zakres i Cel Przetwarzania Danych Osobowych</h3>
							<p>
								3.1. Administrator przetwarza dane osobowe Użytkowników w zakresie niezbędnym do świadczenia usług
								Serwisu, w szczególności:
							</p>
							<ul>
								<li>Dane podane dobrowolnie przez Użytkownika (np. informacje o sytuacji życiowej).</li>
								<li>Numer telefonu w celu weryfikacji Użytkownika i wysłania kodu dostępu.</li>
							</ul>
						</section>

						<section>
							<h3>4. Podstawa Prawna Przetwarzania Danych</h3>
							<p>4.1. Przetwarzanie danych odbywa się na podstawie art. 6 ust. 1 lit. a) i b) RODO.</p>
						</section>

						<section>
							<h3>5. Prawa Użytkownika</h3>
							<p>5.1. Użytkownik ma prawo do:</p>
							<ul>
								<li>Dostępu do swoich danych osobowych (art. 15 RODO).</li>
								<li>Sprostowania danych (art. 16 RODO).</li>
								<li>Usunięcia danych ("prawo do bycia zapomnianym") (art. 17 RODO).</li>
								<li>Ograniczenia przetwarzania danych (art. 18 RODO).</li>
								<li>Przenoszenia danych (art. 20 RODO).</li>
								<li>Wniesienia sprzeciwu wobec przetwarzania danych (art. 21 RODO).</li>
								<li>Cofnięcia zgody na przetwarzanie danych w dowolnym momencie.</li>
							</ul>
							<p>
								5.2. W celu realizacji swoich praw Użytkownik może skontaktować się z Administratorem pod adresem
								e-mail: <a href="mailto:blonekdev@gmail.com">blonekdev@gmail.com</a>.
							</p>
						</section>

						<section>
							<h3>6. Odbiorcy Danych</h3>
							<p>
								6.1. Dane osobowe mogą być przekazywane podmiotom współpracującym z Administratorem, w tym dostawcom
								usług IT (OpenAI API), usług telekomunikacyjnych (Textbelt) oraz płatności elektronicznych (HotPay).
							</p>
						</section>

						<section>
							<h3>7. Pliki Cookies</h3>
							<p>
								7.1. Serwis wykorzystuje pliki cookies w celu zapewnienia prawidłowego działania oraz dostosowania
								treści do preferencji Użytkownika.
							</p>
						</section>

						<section>
							<h3>8. Środki Bezpieczeństwa</h3>
							<p>
								8.1. Administrator stosuje odpowiednie środki techniczne i organizacyjne w celu ochrony danych osobowych
								Użytkowników.
							</p>
						</section>

						<section>
							<h3>9. Postanowienia Końcowe</h3>
							<p>9.1. Administrator zastrzega sobie prawo do zmiany Polityki Prywatności.</p>
						</section>
					</section>
				</div>
			</div>
		</div>
	)
}

export default PrivacyPolicy
