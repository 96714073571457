import React from 'react'
import { Link } from 'react-router-dom'
import './Legal.css'

function TermsOfService() {
	return (
		<div className="legal-page">
			<div className="legal-container">
				<Link to="/" className="legal-back-button">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path
							d="M19 12H5M5 12L12 19M5 12L12 5"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					Wróć do strony głównej
				</Link>

				<h1>Regulamin Serwisu Zasiłki.info</h1>

				<div className="legal-content">
					<section>
						<h2>§1 Postanowienia Ogólne</h2>
						<p>
							1.1. Niniejszy Regulamin określa zasady korzystania z serwisu internetowego Zasiłki.info, dostępnego pod
							adresem <a href="https://zasilki.info">https://zasilki.info</a>, prowadzonego przez BLONEKDEV Antoni
							Bartłomiej Blonkowski z siedzibą w Mławka 25, 13-240 Mławka, NIP: 5711727874, e-mail:{' '}
							<a href="mailto:blonekdev@gmail.com">blonekdev@gmail.com</a>.
						</p>
						<p>
							1.2. Regulamin jest udostępniony nieodpłatnie za pośrednictwem Serwisu w formie, która umożliwia jego
							pobranie, utrwalenie i wydrukowanie.
						</p>
						<p>1.3. Warunkiem korzystania z Serwisu jest akceptacja niniejszego Regulaminu.</p>
					</section>

					<section>
						<h2>§2 Definicje</h2>
						<p>
							2.1. <strong>Serwis</strong> – serwis internetowy Zasiłki.info dostępny pod adresem{' '}
							<a href="https://zasilki.info">https://zasilki.info</a>.
						</p>
						<p>
							2.2. <strong>Użytkownik</strong> – każda osoba korzystająca z Serwisu.
						</p>
						<p>
							2.3. <strong>Administrator</strong> – BLONEKDEV Antoni Bartłomiej Blonkowski z siedzibą w Mławka 25,
							13-240 Mławka, NIP: 5711727874.
						</p>
						<p>
							2.4. <strong>Usługi</strong> – usługi świadczone drogą elektroniczną przez Administratora za pośrednictwem
							Serwisu.
						</p>
					</section>

					<section>
						<h2>§3 Rodzaj i Zakres Świadczonych Usług</h2>
						<p>
							3.1. Serwis umożliwia Użytkownikom generowanie listy dostępnych zasiłków na podstawie opisu sytuacji
							życiowej Użytkownika, z wykorzystaniem OpenAI API.
						</p>
						<p>
							3.2. Jeden zasiłek jest wyświetlany Użytkownikowi bezpłatnie; pozostałe są dostępne po dokonaniu
							płatności.
						</p>
						<p>3.3. Serwis wykorzystuje technologie: Redis, Textbelt, Hotpay, epxerr, React, OpenAI.</p>
					</section>

					<section>
						<h2>§4 Warunki Świadczenia Usług</h2>
						<p>
							4.1. Do korzystania z Serwisu niezbędne jest posiadanie urządzenia z dostępem do Internetu oraz
							przeglądarki internetowej obsługującej JavaScript i pliki cookies.
						</p>
						<p>
							4.2. Użytkownik zobowiązuje się do korzystania z Serwisu zgodnie z obowiązującym prawem, postanowieniami
							niniejszego Regulaminu oraz dobrymi obyczajami.
						</p>
						<p>4.3. Użytkownik zobowiązuje się do nieprzekazywania treści o charakterze bezprawnym.</p>
					</section>

					<section>
						<h2>§5 Warunki Zawierania i Rozwiązywania Umów</h2>
						<p>
							5.1. Umowa o świadczenie usług drogą elektroniczną zostaje zawarta w momencie rozpoczęcia korzystania z
							Serwisu przez Użytkownika.
						</p>
						<p>
							5.2. Umowa o świadczenie usług polegających na udostępnieniu pełnej listy zasiłków zostaje zawarta z
							chwilą dokonania płatności przez Użytkownika.
						</p>
						<p>5.3. Użytkownik może w każdej chwili zakończyć korzystanie z usług Serwisu.</p>
					</section>

					<section>
						<h2>§6 Płatności</h2>
						<p>6.1. Dostęp do pełnej listy zasiłków wymaga wniesienia opłaty zgodnie z cennikiem podanym w Serwisie.</p>
						<p>6.2. Płatności realizowane są za pośrednictwem systemu płatności HotPay.</p>
						<p>6.3. Rozliczenia transakcji e-przelewem przeprowadzane są za pośrednictwem HotPay.</p>
						<p>
							6.4. Użytkownik dokonuje płatności poprzez podanie numeru telefonu, na który zostanie wysłany kod dostępu
							za pomocą usługi Textbelt.
						</p>
					</section>

					<section>
						<h2>§7 Ograniczenia w Dostarczaniu Usług</h2>
						<p>
							7.1. Serwis może nie być dostępny w niektórych krajach lub regionach ze względu na ograniczenia prawne lub
							techniczne.
						</p>
					</section>

					<section>
						<h2>§8 Reklamacje</h2>
						<p>
							8.1. Użytkownik ma prawo zgłaszać reklamacje dotyczące funkcjonowania Serwisu oraz świadczonych usług.
						</p>
						<p>
							8.2. Reklamacje należy składać na adres e-mail:{' '}
							<a href="mailto:blonekdev@gmail.com">blonekdev@gmail.com</a>.
						</p>
						<p>8.3. Administrator rozpatruje reklamację w terminie 14 dni od dnia jej otrzymania.</p>
					</section>

					<section>
						<h2>§9 Prawo Odstąpienia od Umowy</h2>
						<p>
							9.1. Zgodnie z art. 38 pkt 13 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, Użytkownikowi nie
							przysługuje prawo odstąpienia od umowy o świadczenie usług, jeżeli Administrator wykonał w pełni usługę za
							wyraźną zgodą Użytkownika.
						</p>
					</section>

					<section>
						<h2>§10 Odpowiedzialność</h2>
						<p>
							10.1. Administrator dokłada wszelkich starań, aby informacje prezentowane w Serwisie były aktualne i
							rzetelne, jednak nie gwarantuje ich pełnej dokładności i kompletności.
						</p>
						<p>
							10.2. Informacje o dostępnych zasiłkach generowane przez Serwis mają charakter informacyjny i nie stanowią
							wiążącej oferty ani porady prawnej.
						</p>
						<p>
							10.3. Administrator nie ponosi odpowiedzialności za decyzje podjęte przez Użytkownika na podstawie
							informacji uzyskanych w Serwisie.
						</p>
					</section>

					<section>
						<h2>§11 Ochrona Danych Osobowych</h2>
						<p>
							11.1. Zasady przetwarzania danych osobowych Użytkowników określa Polityka Prywatności dostępna w Serwisie.
						</p>
					</section>

					<section>
						<h2>§12 Postanowienia Końcowe</h2>
						<p>
							12.1. Administrator zastrzega sobie prawo do zmiany Regulaminu. O wszelkich zmianach Użytkownicy zostaną
							poinformowani poprzez publikację nowej treści Regulaminu na stronie Serwisu.
						</p>
						<p>
							12.2. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy prawa polskiego, w
							szczególności Kodeksu cywilnego oraz ustawy o świadczeniu usług drogą elektroniczną.
						</p>
						<p>12.3. Niniejszy Regulamin obowiązuje od dnia publikacji w Serwisie.</p>
					</section>
				</div>
			</div>
		</div>
	)
}

export default TermsOfService
